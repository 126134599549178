<template>
  <div class="home">
    <Layout
      :style="{ minHeight: '100vh' }"
      style="flex-direction: column;"
      class="background-container"
    >
      <top-header></top-header>
      <Layout style="flex-direction: row;">
        <iot-menu></iot-menu>
        <div class="main-content-box">
          <keep-alive>
            <!-- 需要缓存的视图组件 -->
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <!-- 不需要缓存的视图组件 -->
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
      </Layout>
    </Layout>
  </div>
</template>
<script>
import iotmenu from "../../components/index/iotmenu";
import topHeader from "../../components/index/topheader";

export default {
  components: {
    "iot-menu": iotmenu,
    "top-header": topHeader
  },
  computed: {
    // cachedViews() {
    //   // return this.$store.state.tagsView.cachedViews;
    // },
    key() {
      return this.$route.path;
    }
  }
};
</script>

<style lang="scss">
.background-container {
  .main-content-box {
    width: 100%;
    overflow: scroll;
    background-color: #ffffff;
    padding: 30px;
    &::-webkit-scrollbar {
      width: 0px;
      opacity: 0;
      -webkit-overflow-scrolling: touch;
    }
  }
}
</style>
