<template>
  <div id="topHeader">
    <div class="title">
      <div class="title_container">
        <span class="title_content"> 织造数字车间管控系统</span>
      </div>
    </div>
    <div class="headerNavContainer">
      <el-tabs
        v-model="tagName"
        type="card"
        @tab-remove="removeTab"
        @tab-click="cliclTab"
      >
        <el-tab-pane
          v-for="tag in visitedViews"
          :key="tag.path"
          :label="tag.title"
          :name="tag.name"
          closable
        >
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tagName: "index"
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.visitedViews;
    },
    routes() {
      return this.$store.state.permission.routes;
    }
  },
  watch: {
    $route(val) {
      this.addTags();
      this.tagName = val.name;
    }
  },
  mounted() {
      this.addTags();
    // this.visitedViews = this.$store.state.visitedViews;
    this.tagName = this.visitedViews.filter((item) => {
      return item.path === this.$route.path;
    })[0].name;
  },
  methods: {
    removeTab(targetName) {
      const tabs = this.visitedViews;
      let activeName = this.tagName;
      let delViewTab = null;
      tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
          delViewTab = tab;
          const nextTab = tabs[index + 1] || tabs[index - 1];
          if (nextTab) {
            activeName = nextTab.name;
          }
        }
      });
      this.tagName = activeName;
      // const visitedViews = tabs.filter((tab) => tab.name !== targetName);
      // this.$store.dispatch("setView", visitedViews);
      this.closeSelectedTag(delViewTab);
    },
    cliclTab(e) {
      this.$router.push({
        path: this.visitedViews[e.index].path,
        query: this.visitedViews[e.index].query
      });
    },
    addTags() {
      const { name } = this.$route;
      if (name) {
        this.$store.dispatch("addView", this.$route);
      }
      const length = this.visitedViews.length - 1;
      this.tagName = this.visitedViews[length].name;
      return false;
    },
    closeSelectedTag(view) {
      this.$store.dispatch("delView", view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view);
        }
      });
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView);
      } else {
        if (view.name === "Dashboard") {
          // to reload home page
          this.$router.replace({ path: "/redirect" + view.fullPath });
        } else {
          this.$router.push("/");
        }
      }
    }
  }
};
</script>

<style lang="scss">
#topHeader {
  display: flex;
  background-color: #0d47a1;
  height: 46px;
  width: 100%;
  .title {
    width: 200px;
    line-height: 46px;
    text-align: center;

    .title_container {
      height: 27px;
      span {
        line-height: 27px;
        font-size: 18px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
  .headerNavContainer {
    box-sizing: border-box;
    width: calc(100% - 240px);
    display: inline-block;
    .tags-view-wrapper {
      width: 100%;
      height: 52px;
      .tags-view-item {
        width: 1rem;
        text-align: center;
      }
    }
    // 顶部标签栏的样式
    .el-tabs--card > .el-tabs__header,
    .el-tabs--card > .el-tabs__header .el-tabs__nav {
      border: none;
    }
    .el-tabs__item {
      height: 47px;
      line-height: 47px;
      font-size: 16px;
      color: #fff;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item {
      border: none;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
      background: #fff;
      color: #000;
    }
    .el-tabs__nav .el-tabs__item:nth-child(1) span {
      display: none;
    }
  }
  .el-tabs__content {
    // display: none;
  }
}
</style>
