<template>
  <div class="sliderShow" id="slider">
    <Sider
      collapsible
      :collapsed-width="56"
      :class="menuitemClasses"
      v-model="isCollapsed"
    >
      <el-menu
        default-active="1-4-1"
        class="el-menu-vertical-demo"
        router
        :collapse="isCollapsed"
        background-color="#F5F5F5"
        unique-opened
        active-text-color="#ffffff"
      >
        <template v-for="(item, index) in menuInfo">
          <template v-if="item.list">
            <el-submenu :index="index.toString()" :key="index.toString()">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <i :class="item.ztthorityCron"></i>
                <span slot="title"> {{ item.authorityName }} </span>
              </template>
              <el-menu-item-group
                v-for="(itemin, index) in item.list"
                :key="index"
              >
                <el-menu-item
                  v-if="itemin.authorityName !== '退出'"
                  :index="itemin.ztthorityUrl"
                  >{{ itemin.authorityName }}</el-menu-item
                >
                <el-menu-item
                  v-if="itemin.authorityName === '退出'"
                  @click="loginOut"
                  >{{ itemin.authorityName }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-if="!item.list">
            <el-menu-item
              :index="item.ztthorityUrl + ''"
              :key="item.ztthorityUrl + ''"
            >
              <i :class="item.ztthorityCron"></i>
              <span slot="title"> {{ item.authorityName }} </span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </Sider>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      isCollapsed: false,
      activeMenu: 0,
      activeSubmenu: [],
      menuList: [], // 获取菜单数据
      currentPath: "", // 获取当前路由判断高亮
      menuInfo: ""
    };
  },
  watch: {},
  computed: {
    menuitemClasses: function() {
      return ["menu-item", this.isCollapsed ? "collapsed-menu" : ""];
    }
  },
  methods: {
    loginOut() {
      this.axios({
        url: "/dtsum/zongtong/organization/LoginController/loginOut",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("authorityList");
            sessionStorage.removeItem("store");
            this.$store.dispatch("delAllVisitedViews")
            this.$Message.success("已退出");
            this.$router.replace({ path: "/" });
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {
    this.menuInfo = JSON.parse(localStorage.getItem("authorityList"));
  },
  mounted() {}
};
</script>

<style lang="scss">
.sliderShow {
  margin-top: 1px;
}
.ivu-layout-sider-trigger {
  border-radius: 2px;
  height: 40px;
  line-height: 40px;
  color: #222;
}
// 侧边栏上方标题
.menutitle {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  background: #0d47a1;
  font-size: 18px;
  font-weight: bold;
  img {
    position: relative;
    top: 3px;
  }
}
.el-menu-item,
.el-submenu__title {
  height: 40px;
  line-height: 40px;
}
.el-menu-item-group__title {
  padding: 0;
}
.el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
  &:last-child() {
    height: 32px !important;
    line-height: 32px !important;
  }
}
// 修改菜单激活时的背景颜色
.el-menu-item.is-active {
  background-color: #1565c0 !important;
}
// 去除导航菜单右边的线条
.el-menu {
  border-right: 0 !important;
}
.el-tooltip__popper.is-dark {
  background-color: #f5f5f5;
  color: #000;
  border: 1px solid #888;
}
.el-menu--popup-right-start {
  margin-left: 0px;
}
</style>
